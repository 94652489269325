@import url("https://fonts.googleapis.com/css?family=Lato:700|Lato:400");

/* nunito-sans-200 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-200italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 200;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-300italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-regular.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-regular.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-600 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-600.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-600.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-600italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 600;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-600italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-600italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-700.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-700.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-700italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-700italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-800 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-800.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-800.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-800italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 800;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-800italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-800italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-900 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-900.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-900.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-900italic - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-900italic.woff2")
      format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-900italic.woff")
      format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Font metric overrides reduces CLS from font-swap.
This is manually inserted, metric generated using CLI commands:
npx fontpie ./public/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-200.woff2 -weight 200 -style normal 
npx fontpie ./public/fonts/nunito-sans/nunito-sans-v12-latin_latin-ext-300italic.woff2 -weight 300 -style italic 
etc...
*/
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: normal;
  font-weight: 200;
  src: local("Arial");
  ascent-override: 103.02%;
  descent-override: 35.97%;
  line-gap-override: 0%;
  size-adjust: 98.13%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: italic;
  font-weight: 200;
  src: local("Arial Italic");
  ascent-override: 103.02%;
  descent-override: 35.97%;
  line-gap-override: 0%;
  size-adjust: 98.13%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: normal;
  font-weight: 300;
  src: local("Arial");
  ascent-override: 101.29%;
  descent-override: 35.37%;
  line-gap-override: 0%;
  size-adjust: 99.82%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: italic;
  font-weight: 300;
  src: local("Arial Italic");
  ascent-override: 101.27%;
  descent-override: 35.36%;
  line-gap-override: 0%;
  size-adjust: 99.84%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: normal;
  font-weight: 400;
  src: local("Arial");
  ascent-override: 99.49%;
  descent-override: 34.74%;
  line-gap-override: 0%;
  size-adjust: 101.62%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: italic;
  font-weight: 400;
  src: local("Arial Italic");
  ascent-override: 99.49%;
  descent-override: 34.74%;
  line-gap-override: 0%;
  size-adjust: 101.62%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: normal;
  font-weight: 600;
  src: local("Arial Bold");
  ascent-override: 105.78%;
  descent-override: 36.94%;
  line-gap-override: 0%;
  size-adjust: 95.57%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: italic;
  font-weight: 600;
  src: local("Arial Bold Italic");
  ascent-override: 105.78%;
  descent-override: 36.94%;
  line-gap-override: 0%;
  size-adjust: 95.57%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: normal;
  font-weight: 700;
  src: local("Arial Bold");
  ascent-override: 103.63%;
  descent-override: 36.18%;
  line-gap-override: 0%;
  size-adjust: 97.56%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: italic;
  font-weight: 700;
  src: local("Arial Bold Italic");
  ascent-override: 103.63%;
  descent-override: 36.18%;
  line-gap-override: 0%;
  size-adjust: 97.56%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: normal;
  font-weight: 800;
  src: local("Arial Bold");
  ascent-override: 101.35%;
  descent-override: 35.39%;
  line-gap-override: 0%;
  size-adjust: 99.75%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: italic;
  font-weight: 800;
  src: local("Arial Bold Italic");
  ascent-override: 101.35%;
  descent-override: 35.39%;
  line-gap-override: 0%;
  size-adjust: 99.75%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: normal;
  font-weight: 900;
  src: local("Arial Bold");
  ascent-override: 99.1%;
  descent-override: 34.6%;
  line-gap-override: 0%;
  size-adjust: 102.02%;
}
@font-face {
  font-family: "Nunito Sans Fallback";
  font-style: italic;
  font-weight: 900;
  src: local("Arial Bold Italic");
  ascent-override: 99.1%;
  descent-override: 34.6%;
  line-gap-override: 0%;
  size-adjust: 102.02%;
}
